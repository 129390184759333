const downloadImage = async (
	topicId: string,
	topicName: string,
	fileType: string = 'svg',
	endpoint: string = 'exhibit'
) => {
	const url =
		endpoint === 'exhibit'
			? `${process.env.REACT_APP_LIIINGO_URL}/${endpoint}/qr?id=${topicId}&fileType=${fileType}`
			: `${process.env.REACT_APP_LIIINGO_URL}/${endpoint}/${fileType}?id=${topicId}`;
	const response = await fetch(url);
	const blob = await response.blob();
	const downloadLink = document.createElement('a');
	downloadLink.href = URL.createObjectURL(blob);
	downloadLink.download = `${topicName}.${fileType}`;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
	URL.revokeObjectURL(downloadLink.href);
};

export { downloadImage };
