import { ReactNode, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { _topicIsLoading, _topics, duplicateTopic } from '../../../store/slices/topicSlice';
import { downloadImage } from '../../../util/downloadQrcode';
import { LiiingoContextMenu } from '../../Dashboard/CardComponents/Menu/LiiingoContextMenu';

export type PageContextMenuProps = {
	children: ReactNode;
	topicId: string;
	disableDelete: boolean;
	disableDown: boolean;
	disableUp: boolean;
	editPageName: () => void;
	deletePage: () => void;
	copyPageLink: () => void;
	moveUp: () => void;
	moveDown: () => void;
};

export const PageContextMenu = (props: PageContextMenuProps) => {
	const {
		children,
		topicId,
		disableDelete,
		disableDown,
		disableUp,
		editPageName,
		copyPageLink,
		deletePage,
		moveUp,
		moveDown,
	} = {
		...props,
	};

	const dispatch = useAppDispatch();
	const duplicateTopicBinder = bindActionCreators(duplicateTopic, dispatch);
	const topicIsLoading = useAppSelector(_topicIsLoading);
	const [options, setOptions] = useState([]);
	const index = 3;
	const topics = useAppSelector(_topics);
	const selectedTopic = topics[topicId];
	useEffect(() => {
		let baseOptions = [
			{
				text: 'Copy Page Link',
				handleClick: copyPageLink,
			},
			{
				text: 'Download PNG',
				handleClick: () => downloadImage(topicId, selectedTopic.name[0].name, 'png'),
			},
			{
				text: 'Download SVG',
				handleClick: () => downloadImage(topicId, selectedTopic.name[0].name, 'svg'),
			},
			{ text: 'Rename', handleClick: editPageName },
			{
				text: 'Delete',
				disabled: disableDelete,
				handleClick: deletePage,
			},
			{
				text: 'Move Up',
				disabled: disableUp,
				handleClick: moveUp,
			},
			{
				text: 'Move Down',
				disabled: disableDown,
				handleClick: moveDown,
			},
		];
		if (topicIsLoading) {
			setOptions([
				...baseOptions.slice(0, index),
				{
					text: 'Loading...',
					disabled: true,
					handleClick: () => {},
				},
				...baseOptions.slice(index),
			]);
		} else {
			setOptions([
				...baseOptions.slice(0, index),
				{ text: 'Duplicate', handleClick: () => duplicateTopicBinder(topicId) },
				...baseOptions.slice(index),
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topicIsLoading]);

	return (
		<LiiingoContextMenu id={topicId} options={options}>
			{children}
		</LiiingoContextMenu>
	);
};
