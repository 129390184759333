import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _selectedLocation } from '../../store/slices/locationSlice';
import { _selectedTopic } from '../../store/slices/topicSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { LiiingoWarning } from '../LiiingoWarning';
import { Throbber } from '../Throbber';
import { CardTitle } from './CardComponents/CardTitle';
import { QRContextMenu } from './CardComponents/Menu/QRContextMenu';
import { QRMenu } from './CardComponents/Menu/QRMenu';
import { QREditor } from './QREditor/QREditor';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		skeleton: {
			margin: '20px auto',
		},
		formCard: {
			marginBottom: 20,
			backgroundColor: theme.palette.background.paper,
			width: 500,
		},
		spinnerBox: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 247,
		},
		content: {
			width: 250,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		button: {
			height: 32,
			width: 155,
		},
		qrContent: {
			width: 200,
			padding: 8,
			// backgroundColor: colors.grayLight20,
		},
		bigBox: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		contentBox: {
			width: 500,
			height: 239,
			display: 'flex',
			marginBottom: 8,
		},
		titleBox: {
			display: 'flex',
			alignItems: 'center',
		},
		image: {
			width: 184,
			height: 184,
			margin: '20px',
		},
	})
);

export type QRCardProps = {
	loading: boolean;
	primaryColor: string;
	secondaryColor: string;
};

// The QR Card now displays the QrCode from the selected Page, rather than a non-working example QR.
// Get zip/path info from the selectedTopic
export const QRCard = (props: QRCardProps) => {
	const { loading, primaryColor, secondaryColor } = props;
	const [openEditor, setOpenEditor] = useState(false);
	const classes = useStyles();
	const selectedTopic = useAppSelector(_selectedTopic);
	const location = useAppSelector(_selectedLocation);
	const [qrUrl, setQrUrl] = useState(
		process.env.REACT_APP_LIIINGO_URL + '/exhibit/qr?id=' + selectedTopic?.id + '&t=' + Date.now()
	);

	//TODO: figure out when to trigger warning for QR colors being too light. This is always false
	const warning = false;

	const handleOpen = () => {
		setOpenEditor(true);
	};

	useEffect(() => {
		setTimeout(() => {
			setQrUrl(process.env.REACT_APP_LIIINGO_URL + '/exhibit/qr?id=' + selectedTopic?.id + '&t=' + Date.now());
		}, 1000);
	}, [openEditor, selectedTopic?.id, location]);

	const Menu = () => (
		<>
			<QRMenu topicId={selectedTopic?.id} />
		</>
	);

	return (
		<Card variant="outlined" className={classes.formCard}>
			<QRContextMenu topicId={selectedTopic?.id}>
				<Box className={classes.bigBox}>
					<Box className={classes.contentBox}>
						<CardContent className={classes.content}>
							<Box className={classes.titleBox}>
								{warning && (
									<LiiingoWarning message="Your app's QR Code contains a light color that may not scan on a white background." />
								)}
								<CardTitle title="QR Code" menu={Menu} msg="QR Code Options" />
							</Box>
							<Typography variant="body2">
								Create a custom QR code that is recognizable to your audience by adding brand colors and
								a logo.
							</Typography>
							<FlatButton
								className={classes.button}
								variant="contained"
								color="secondary"
								onClick={handleOpen}
							>
								Design QR Code
							</FlatButton>
							<QREditor
								open={openEditor}
								setOpen={(open: boolean) => setOpenEditor(open)}
								primary={primaryColor}
								secondary={secondaryColor}
							/>
						</CardContent>
						<CardContent className={classes.qrContent}>
							<Throbber isVisible={loading} />
							{selectedTopic && <img src={qrUrl} alt="QR Code" className={classes.image} />}
						</CardContent>
					</Box>
				</Box>
			</QRContextMenu>
		</Card>
	);
};
