import axiosInstance from '.';
import { Location, newContent, newLocation, newSection, newTopic, Topic } from '../models';
import { cloneAppPayload } from '../slices/locationSlice';

export const getAllLocations = () => {
	return axiosInstance
		.post<Location[]>('/location/get', {})
		.then((res: any) => res.data.map((r: any) => newLocation(r)));
};

//TODO: Implement this endpoint in PHP api
export const getLocationsByEmail = async (email: string) => {
	return axiosInstance
		.post<Location[]>('/location/getLocationsByEmail', { email })
		.then((res: any) => res.data.map((r: any) => newLocation(r)));
};

export const getLocationsByOrgId = (organization_id) => {
	return axiosInstance
		.post<Location[]>('/location/getLocationsByOrgId', { organization_id })
		.then((res: any) => res.data.map((r) => newLocation(r)));
};

export const getLocationById = (locationId: string) => {
	return axiosInstance
		.post<Location>('/location/get', { locationId: locationId })
		.then((res) => newLocation(res.data));
};

// Clone App
export const cloneApp = (payload: cloneAppPayload) => {
	return axiosInstance.post('/location/cloneApp', payload).then((res: any) => res.data);
};

// probably going to rewrite this later; generalize for all location images (splashScreenLogo, background, customQrLogo, mobileBackground, mobileLogo)
export const saveHeaderLogo = async (location: Location, file: File) => {
	const formData = new FormData();
	formData.append('headerLogo', file);
	let locationClone = structuredClone(location);
	locationClone.areaOrder = locationClone.sectionOrder; // set areaOrder === sectionOrder for legacy PHP code
	formData.append(`location`, JSON.stringify(locationClone));
	const response = await axiosInstance.post('/location/save', formData);
	return response.data;
};

export const saveQrLogo = async (location: Location, file: File) => {
	const formData = new FormData();
	formData.append('customQrLogo', file);
	let locationClone = structuredClone(location);
	locationClone.areaOrder = locationClone.sectionOrder; // set areaOrder === sectionOrder for legacy PHP code
	formData.append('location', JSON.stringify(locationClone));
	const response = await axiosInstance.post('/location/save', formData);
	return response.data;
};

export const saveLocation = async (location: Location) => {
	const formData = new FormData();
	// make a modifiable copy of location object
	let locationClone = structuredClone(location);

	// if qr_code_logo or qr_code_background is a file, append it to formData and remove it from locationClone
	if (location?.qrcodeProperties?.qr_code_logo instanceof File) {
		formData.append('qr_code_logo', location.qrcodeProperties.qr_code_logo);
		delete locationClone.qrcodeProperties.qr_code_logo;
	}
	if (location?.qrcodeProperties?.qr_code_background instanceof File) {
		formData.append('qr_code_background', location.qrcodeProperties.qr_code_background);
		delete locationClone.qrcodeProperties.qr_code_background;
	}

	// set areaOrder === sectionOrder for PHP naming madness
	locationClone.areaOrder = locationClone.sectionOrder;
	// append locationClone to formData
	// we dont loop over keys and add them to the formData because it doesnt work well with arrays and objects as values
	formData.append(`location`, JSON.stringify(locationClone));

	return axiosInstance.post('/location/save', formData).then((res: any) => res.data);
};

// combined 3 methods above, not using
export const saveLocationCOMBINED = async (location: Location, headerLogo: File = null, customQrLogo: File = null) => {
	const formData = new FormData();
	if (headerLogo) {
		formData.append('file[0]', headerLogo); //TODO: does this have to be named file[0]? I dont get it.
	}
	if (customQrLogo) {
		formData.append('customQrLogo', customQrLogo);
	}
	let updatedLocation = Object.assign({}, location, { areaOrder: location.sectionOrder }); // set areaOrder === sectionOrder for legacy PHP code
	formData.append(`location`, JSON.stringify(updatedLocation));
	const response = await axiosInstance.post('/location/save', formData);
	return response.data;
};

export const deleteLocationImg = (locationId: string, imgField: string) => {
	return axiosInstance
		.post('/location/deleteImg', {
			locationId: locationId,
			imgField: imgField,
		})
		.then((res: any) => res.data);
};

export const saveQrSettings = async (qrSettings: any, locationId) => {
	try {
		const formData = new FormData();

		if (!!qrSettings.logo) {
			formData.append(`customQrLogo`, qrSettings.logo);
		}

		const data = { color: qrSettings.color, locationId: locationId };
		formData.append(`customQrSettings`, JSON.stringify(data));
		const response = await axiosInstance.post('/location/saveCustomQrSettings', formData);
		return response.data;
	} catch (err) {}
};

export const getTopicOfParent = (id, parentLocationId) => {
	return axiosInstance
		.post<Topic[]>('/location/getParent', { id, parentLocationId })
		.then((res: any) => res.data.map((r) => newTopic(r)));
};

// this isn't really a location endpoint, but there isnt a separate file for translation endpoints,
// we dont need one. This is the only translation endpoint. and it uses the locationId as a parameter, so why not?
export const translate = (languageCodes: string[], areaIds: string[], exhibitIds: string[]) => {
	return axiosInstance.post('/translations/write', { languageCodes, areaIds, exhibitIds }).then((res: any) => {
		let { areas, exhibits, contents } = res.data;
		let sections = areas.map((s) => newSection(s));
		let topics = exhibits.map((t) => newTopic(t));
		let content = contents.map((c) => newContent(c));
		return { sections, topics, content };
	});
};

// call updateAreaOrder endpoint
export const updateAreaOrder = (locationId: string, areaOrder: string[]) => {
	return axiosInstance.post('/location/updateAreaOrder', { locationId, areaOrder }).then((res: any) => {
		return res.data; // only reurns a message, no need to map to a model
	});
};
