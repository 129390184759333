import Bugsnag from '@bugsnag/js';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';
import { routerMiddleware } from 'connected-react-router';
import { differenceInDays, formatISO, subDays } from 'date-fns';
import { createBrowserHistory } from 'history';
import { AnyAction } from 'redux';
import { logger } from 'redux-logger';
import * as api from './api-client';
import { urlToFile } from './api-client';
import { aggregateDownloads } from './models/analytics/Downloads';
import { aggregatePageViews } from './models/analytics/PageViews';
import { aggregateSession } from './models/analytics/Session';
import { aggregateTopContent, prepareTopContent } from './models/analytics/TopContent';
import { aggregateHourly, aggregateTraffic } from './models/analytics/Traffic';
import { aggregateTrafficDaily } from './models/analytics/TrafficDaily';
import { aggregateViewsBy } from './models/analytics/ViewsBy';
import { Status } from './models/status/Status';
import rootReducer from './rootReducer';
import {
	getAnalyticsData,
	getDownloads,
	getHourly,
	getPageViews,
	getSession,
	getTopContent,
	getTraffic,
	getTrafficDaily,
	getViewsBy,
	setIsLoading,
} from './slices/analyticsSlice';
import { fetchAllContents, selectContentIds, updateContent } from './slices/contentSlice';
import { fetchStripeInfo } from './slices/editorSlice';
import { changeCompanyName, deleteSectionFromLocation, setIsLoading as isLoading } from './slices/locationSlice';
import { fetchNotifications } from './slices/notificationSlice';
import { fetchOrganization } from './slices/organizationSlice';
import { fetchRedirects, setIsCreating } from './slices/redirectSlice';
import { fetchSections, selectSection, updateSection, updateTopicOrder } from './slices/sectionSlice';
import {
	addQr,
	deleteTopics,
	dropCreateNewTopicFlag,
	fetchTopics,
	selectTopic,
	setIsLoading as setTopicIsLoading,
} from './slices/topicSlice';

const isDev = window.location.hostname !== 'start.liiingo.com'; //couldn't use our useIsDev() hook here, outside of a function component

let liiingoMiddleware: Middleware;
let loggerMiddleware: Middleware = isDev ? logger : () => (next) => (action) => next(action);

export const history = createBrowserHistory({
	getUserConfirmation() {},
});

// intercept store modifications that should be immediately persisted to the server
// middleware are intended to contain logic with side effects. In addition, middleware can modify dispatch to accept things that are not plain action objects.
// Inside each if case, we usually want to call the next(action) function to pass the action to the next middleware or reducer.
// And we usually want to return in order to short circuit the middleware (and keep tighter control of what we're returning).
liiingoMiddleware = (store) => (next) => (action) => {
	// MIDDLEWARE HELPER FUNCTION
	function filterSessionData(locationId: any) {
		let rawData = store.getState().analytics.rawData;
		let rawSessionData = rawData.filter((data) => data.type === 'session');
		// filter out each rawSessionData's viewedContent items which are not from the selected location
		rawSessionData = rawSessionData.map((session) => {
			if (session.viewedContent) {
				session.viewedContent = session.viewedContent?.filter((content) => content.locationId === locationId);
			}
			return session;
		});
		return rawSessionData;
	}

	// MIDDLEWARE ACTION TYPE CASES

	// SAVING NEW TOPICS
	// add page locally, save section with updated save to network and then update local page with returned qr code
	if (action.type === 'topic/addTopic/fulfilled') {
		next(action); // calling next(action) allows the action to pass trhu and complete doing its thing, like updating local state
		// fire off save section to network
		let section = store.getState().section.sections[action.payload.sectionId]; // after local state is updated, we use getState() to get the latest values
		return api.saveSection(section).then((response) => {
			//fire off save topic to network, use it's returned values to update local state
			return api.saveTopic(action.payload).then((response) => {
				// update the page with the returned qr code
				const updatedTopic = { ...action.payload, ...response };
				const { id } = updatedTopic;
				store.dispatch(dropCreateNewTopicFlag({ id }));
			});
		});
	}

	// SAVING ORGANIZATION TO NETWORK AFTER UPDATING LOCAL COPY. ALSO COPY THE ORG NAME TO THE LOCATION.COMPANYNAME AND SAVE THE LOCATION TO THE NETWORK
	// When the organization.name is updated, we need to also update the location.companyName with the same value. This is for mobile app "backwards" compatibility.
	if (action.type === 'organization/saveOrganization') {
		next(action);
		let { organizations, selectedOrgId } = store.getState().organization;
		let organization = organizations[selectedOrgId];
		store.dispatch(changeCompanyName(organization.name)); //set location.companyName to the same value as organization.name. This will also trigger a location save to the network.
		return api.updateOrganization(organization); //save the updated organization
	}

	// FETCHING ORGANIZATION DURING HYDRATION.
	// When hydrating the store, we need to fetch the organization from the server.
	// The UI will dispatch the action and the payload will only contain the org's id.
	// We'll use that id to fetch the org from the server and then modify the action's payload with the response.
	// Then we'll pass the action to the reducer where it will populate the org in the store.
	if (action.type === 'organization/fetchOrganization') {
		// fetch org over network
		return api.getOrganizationById(action.payload).then((response) => {
			// modify the action's payload with the response
			action.payload = response;
			// pass the action to the reducer
			return next(action);
		});
	}

	// CREATING A NEW ORGANIZATION
	// api call to createNewOrg is being made in the sign-up file, so we can await the result.
	if (action.type === 'organization/createNewOrganization') {
		return next(action); //just forward the action along
	}

	// CLONING A TEMPLATE FOR THE NEW APP
	// During sign up, we clone a template for the new app.
	// We're making the api call from the sign-up page now, so we can await the reult
	if (action.type === 'location/cloneApp') {
		return next(action); //just forward the action along
	}

	// FETCHING THE PLAN BY NAME
	// During sign-up, we only know the user-selected plan type by it's name. We'll use this to get the plan from the Liiingo db so we can use the priceId while creating a Stripe Subscription.
	if (action.type === 'editor/getPlanByName') {
		return api.getPlanByName(action.payload).then((response) => {
			action.payload = response; // update the action payload with the plan object
			// store.dispatch(setPlan(response));
			next(action);
		});
	}

	// FETCHING THE PLAN
	// During sign-in, we only know which priceId the user has in their Stripe Subscriotion. We'll use this to get the plan from the Liiingo db so we can determine if the user has access (permissions) to certain features.
	if (action.type === 'editor/getPlanByPriceId') {
		return api.getPlanByPriceId(action.payload).then((response) => {
			action.payload = response; // update the action payload with the plan object
			// store.dispatch(setPlan(response));
			next(action);
		});
	}

	// SAVING TOPIC TO NETWORK AFTER STATUS CHANGE OR SHARING ENABLE/DISABLE OR CHANGING NAME
	// save topic to network when status has changed via clicking the icon in the topic list or after onBlur event when changing the page name
	if (
		action.type === 'topic/topicSetStatus' ||
		action.type === 'topic/topicEnableSharing' ||
		action.type === 'topic/saveTopic'
	) {
		next(action);
		let topic = store.getState().topic.topics[action.payload.topicId];
		return api.saveTopic(topic);
	}

	//DUPLICATING TOPIC TO THE NETWORK
	//Duplicate a topic to network when duplicate button is clicked

	if (action.type === 'topic/duplicateTopic') {
		let newTopic = store.getState().topic.topics[action.payload];
		newTopic = structuredClone(newTopic);
		const oldID = newTopic.id;
		const newTopicID = new ObjectID().toHexString();
		newTopic.id = newTopicID;
		newTopic._id = newTopicID;
		newTopic.name[0].name = newTopic.name[0].name + ' Copy';

		delete newTopic.qr;

		// content
		let newContents = [];
		const oldContents = newTopic.content;
		delete newTopic.content;

		oldContents.forEach((item) => {
			let newContent = store.getState().content.contents[item];
			newContent = structuredClone(newContent);
			newContent.id = new ObjectID().toHexString();
			newContent._id = newContent.id;
			newContent.createNewEntity = true;
			newContent.assignedTopics._id = newTopicID;
			newContents.push(newContent);
		});

		//update the content field on the new topic
		newTopic.content = newContents.map((item) => item.id);
		// update redux with the new contents
		store.dispatch(fetchAllContents(newContents));

		//area
		let areaId = newTopic.sectionId;
		let newTopicOrder = store.getState().section.sections[areaId].topicOrder;
		newTopicOrder = structuredClone(newTopicOrder);
		newTopicOrder.splice(newTopicOrder.indexOf(action.payload) + 1, 0, newTopicID);

		//update the topicOrder on the section in redux
		store.dispatch(updateTopicOrder({ sectionId: areaId, topicOrder: newTopicOrder }));
		// update the topic in redux
		action.payload = structuredClone(newTopic);
		next(action);

		//save area, topic, and contents to network
		newContents.forEach((item) => api.saveClonedContent(item));
		newTopic.createNewEntity = true;
		return api.saveClonedTopic(newTopic).then((response) => {
			const { _id, qr } = response;

			api.updateExhibitImage(response._id, oldID);
			// store.dispatch(dropCreateNewTopicFlag({ id: response._id }));
			store.dispatch(addQr({ id: _id, qr: qr }));
			// api.saveSection(newArea);
		});
	}

	// SAVING TOPIC TO NETWORK (WITH STATUS 'DELETED') BEFORE DELETING LOCALLY
	// save topic to network with status 'deleted'
	if (action.type === 'topic/deleteTopic/pending') {
		//notice we're gathering the topicId from the meta.arg
		let topic = store.getState().topic.topics[action.meta.arg];
		next(action);
		return api.deleteTopic(topic);
	}

	// SAVING TOPIC TO NETWORK AFTER REORDERING HTE TOPIC INTO ANOTHER SECTION
	// save topic to network after reordering the topic into another section
	if (action.type === 'topic/reorderTopic/fulfilled') {
		let topicBefore = store.getState().topic.topics[action.payload.topicId];
		next(action); //this will trigger an "extra reducer" function in the topic slice that will update the topic's sectionId
		let topicAfter = store.getState().topic.topics[action.payload.topicId];
		// if the topic was moved to a different section, then we need to save the topic to the network
		if (topicAfter.sectionId !== topicBefore.sectionId) {
			return api.saveTopic(topicAfter);
		} else {
			return;
		}
	}

	// SAVING THE LOCATION AFTER SECTION HAS BEEN ADDED OR REMOVED FROM SECTION-ORDER, OR AFTER CHANGING THE LOCATION/COMPANY NAME
	// save location to network after modifying the location.name or other misc location properties (besides areOrder)
	if (
		// action.type === 'location/changeLocationName' ||
		action.type === 'location/changeCompanyName' ||
		action.type === 'location/saveLocation' ||
		action.type === 'location/updateLocation' ||
		action.type === 'location/setSelectedLanguages'
	) {
		next(action);
		let { locations, selectedLocationId } = store.getState().location;
		let location = locations[selectedLocationId];
		return api.saveLocation(location);
	}

	// SAVING THE LOCATION AFTER UPDATING THE HEADER LOGO
	// save location to network (w/ attached File) after modifying the location.headerLogo from the mobile preview
	if (action.type === 'location/changeHeaderLogo') {
		next(action);
		let { locations, selectedLocationId } = store.getState().location;
		let location = locations[selectedLocationId];
		// if headerLogo contains a blob type url
		if (location.headerLogo.startsWith('blob')) {
			return urlToFile(location.headerLogo, location.headerLogoImageName).then((headerLogo) => {
				api.saveHeaderLogo(location, headerLogo);
			});
		} else {
			return;
		}
	}

	// SAVING THE QR CODE PROPERTIES
	// save location to network (w/ attached File) after modifying the location.qrcodeProperties. capture returned location object BEFORE calling next(action)
	if (action.type === 'location/updateLocation') {
		let { qrcodeProperties } = action.payload;
		let { locations, selectedLocationId } = store.getState().location;
		let location = locations[selectedLocationId];
		let clonedLocation = structuredClone(location);
		clonedLocation.qrcodeProperties = qrcodeProperties;
		return api.saveLocation(clonedLocation).then((response) => {
			action.payload = response;
			return next(action);
		});
	}

	// SAVING THE LOCATION AFTER ADDING, REMOVING OR REORDERING SECTIONS
	// save location to network after modifying the location.areaOrder
	if (
		action.type === 'location/deleteSectionFromLocation' ||
		action.type === 'location/updateSectionOrder' ||
		action.type === 'location/reorderSection'
	) {
		// let the action fall through so we can obtain the newmly modified location object from the store
		next(action);

		// get updated location object, pull out the sectionOrder and rename using backend lingo.
		// get it? lingo? backend lingo? I'll see myself out.
		let { locations, selectedLocationId } = store.getState().location;
		let location = locations[selectedLocationId];
		let areaOrder = location.sectionOrder;

		// the above action types will only have an effect on the sectionOrder, so we only need to save that part to network (avoiding unnecessary work done by the location/save endpoint)
		return api.updateAreaOrder(selectedLocationId, areaOrder);
	}

	// SAVING THE TOPIC AFTER UPDATING THE BACKGROUND IMAGE
	// save topic to network after modifying the topic.exhibitImage from the mobile preview
	if (action.type === 'topic/changeBackground') {
		store.dispatch(setTopicIsLoading(true));
		let { name, url } = action.payload;
		let { selectedTopicId } = store.getState().topic;
		return api.saveTopicBackground(name, url, selectedTopicId).then((path) => {
			action.payload = path;
			return next(action);
		});
	}

	// SAVING THE SECTION AFTER TOPIC HAS BEEN ADDED OR REMOVED FROM TOPIC-ORDER OR WHEN CHANGING SECTION NAME
	// let the topics get reordered then save the section to the network. Also handle the case where the section name is changed, or really, whenever an explicit call to saveSection is made.
	if (
		action.type === 'section/deleteTopicFromSection' ||
		action.type === 'section/updateTopicOrder' || //updateTopicOrder is where we catch newly added sections, after the page is created and then added to topicOrder
		action.type === 'section/saveSection' // currenytly only used when the section name is changed. Didn't name it saveSectionName because it actually saves the entire section to network
	) {
		next(action);
		let section = store.getState().section.sections[action.payload.sectionId];
		return api.saveSection(section);
	}

	// SAVING THE TOPIC AFTER CONTENT HAS BEEN ADDED, REMOVED, OR REORDER FROM CONTENT-ORDER
	// let the content get reordered then save the topic to the network
	if (
		action.type === 'topic/topicContentAdd' ||
		action.type === 'topic/topicContentDelete' ||
		action.type === 'topic/topicContentReorder'
	) {
		next(action);
		let { topics, selectedTopicId } = store.getState().topic;
		let topic = topics[selectedTopicId]; // we always add, remove, and reorder from the selected topic, so this is safe (for now)
		return api.saveTopic(topic);
	}

	// SAVING CONTENT AFTER IT IS CHANGED, ON-BLUR
	// after content is changed, save the content to the network
	if (
		action.type === 'content/contentChanged' ||
		action.type === 'content/addNewContent/fulfilled' ||
		action.type === 'content/addDuplicateContent/fulfilled'
	) {
		// let { selectedContentId } = store.getState().content; //get this before calling next() so we have the correct contentId, it gets cleared after next() sometimes
		next(action); //let the changes get written to the store
		let id = action.payload.id;
		if (!id) {
			console.error('no contentId in action');
			Bugsnag.notify(`No contentId in action, ${id} was not saved to network`);
			return;
		}
		let { contents } = store.getState().content; // now get the contents map with the new changes
		let content = contents[id];
		return api.saveContent(content).then((data) => {
			store.dispatch(updateContent({ id: content._id, content: data }));
		});
	}

	// SAVING DELETED SECTION TO NETWORK
	// save to network *-before-* deleting the local section object, so it can be used as a param in the API call
	if (action.type === 'section/deleteSection') {
		let sectionId = action.payload.sectionId;
		// set some properties on the section object so it saves correctly to the network
		store.dispatch(updateSection({ _id: sectionId, status: Status.Deleted, createNewEntity: false }));
		let section = store.getState().section.sections[sectionId];

		// clean house before we finish deleting the section
		// delete section's pages, each page's contents
		const topicIds = section.topicOrder;
		if (topicIds) {
			store.dispatch((deleteTopics(topicIds) as unknown) as AnyAction); // deleting a topic will also handle deleting the content. // "as unknown as AnyAction" is a hack to get around the type error
		}

		// remove section from the sectionOrder in location object
		const { locations, selectedLocationId } = store.getState().location;
		store.dispatch(deleteSectionFromLocation(sectionId)); // this updates the location.sectionOrder

		// if the section being deleted is the selected section, select another section. Defaulting to the first one
		if (store.getState().section.selectedSectionId === action.payload.sectionId) {
			// "store.getState().section" is a reference to the redux Slice, not the section object
			const newSelectedSectionId = locations[selectedLocationId].sectionOrder[0];
			store.dispatch(selectSection(newSelectedSectionId));
		}

		// make api call to save the section to the network with a status of 0 (deleted)
		api.deleteSection(section);
		return next(action); // calling next here will allow the action to pass thru and complete doing its thing, like updating local state. This will remove the section from redux
	}

	// SAVING EVERYTHING AFTER ADDING OR REMOVING LANGUAGES - OR WHEN PUBLISH BUTTON IS CLICKED - OR AUTOSAVE
	if (action.type === 'location/saveLanguages/fulfilled' || action.type === 'location/saveEverything/pending') {
		next(action);
		let { locations, selectedLocationId } = store.getState().location;
		let location = locations[selectedLocationId];
		let sections = store.getState().section.sections;
		let topics = store.getState().topic.topics;
		let contents = store.getState().content.contents;

		//TODO: if this field keeps a blob url throughout the lifetime of the component (until the app is refreshed), then we will unnecessarily re-attach and re-save this blob each time the location is saved.
		// if headerLogo contains a blob type url
		if (location.headerLogo.startsWith('blob')) {
			urlToFile(location.headerLogo, location.headerLogoImageName).then((headerLogo) => {
				api.saveHeaderLogo(location, headerLogo);
			});
		}

		// if customQrLogo contains a blob type url
		if (location.customQrLogo.startsWith('blob')) {
			urlToFile(location.customQrLogo, location.customQrLogoImageName).then((customQrLogo) => {
				api.saveQrLogo(location, customQrLogo);
			});
		}

		api.saveLocation(location);

		for (let section of Object.values(sections)) {
			api.saveSection(section);
		}

		for (let topic of Object.values(topics)) {
			api.saveTopic(topic);
		}

		for (let content of Object.values(contents)) {
			api.saveContent(content);
		}

		return;
	}

	// CLICKING APPLY IN THE DATE RANGE PICKER ON THE ANALYTICS DASHBOARD OR VISITING THE APP DASHBOARD (DATERANGE IS AUTOMATICALLY SET TO 30 DAYS)
	// save the new date range to the store, then dispatch an action to fetch new data for each of the analytics charts
	if (action.type === 'analytics/newDateRange') {
		next(action);
		return store.dispatch(getAnalyticsData());
	}

	// FETCHING ALL ANALYTICS DATA
	// when the date range in redux is updated, or when hydrating the store, fetch new data for all the charts
	if (action.type === 'analytics/getAnalyticsData') {
		let { startDate, endDate } = store.getState().analytics.dateRange;
		let locationId = store.getState().location.selectedLocationId;

		// fetch all the data for the charts, store it in the AnalyticsSlice under rawData
		// dispatch all individual chart actions
		// set the analytics slice isLoading to false
		return api
			.getDateRange(startDate, endDate, locationId)
			.then((rawData) => {
				action.payload = rawData;
				next(action);
			})
			.then(() => {
				store.dispatch(getSession());
				store.dispatch(getTraffic());
				store.dispatch(getTopContent());
				store.dispatch(getPageViews());
				store.dispatch(getViewsBy());
				store.dispatch(getDownloads());
				store.dispatch(getTrafficDaily());
				store.dispatch(getHourly());
				store.dispatch(setIsLoading(false));
			});
	}

	// FETCH NEW DATA FOR PAGE VIEW ANALYTICS CHART
	// when the date range in redux is updated, recalculate new data for the page views chart
	if (action.type === 'analytics/getPageViews') {
		let locationId = store.getState().location.selectedLocationId;
		let rawSessionData = filterSessionData(locationId);
		let pageViews = aggregatePageViews(rawSessionData, locationId);
		action.payload = pageViews;
		return next(action);
	}

	// FETCH NEW DATA FOR TRAFFIC ANALYTICS CHART
	// when the date range in redux is updated, recalculate new data for the traffic chart
	if (action.type === 'analytics/getTraffic') {
		let locationId = store.getState().location.selectedLocationId;
		let rawSessionData = filterSessionData(locationId);
		let traffic = aggregateTraffic(rawSessionData, locationId);
		action.payload = traffic;
		return next(action);
	}

	// FETCH NEW DATA FOR DOWNLOADS ANALYTICS CHART
	// when the date range in redux is updated, recalc new data for the downloads chart
	if (action.type === 'analytics/getDownloads') {
		let locationId = store.getState().location.selectedLocationId;
		let rawData = store.getState().analytics.rawData;
		let rawDownloadData = rawData.filter(
			(data) => data.type === 'download' && data.metadata.locationId === locationId
		);
		let downloads = aggregateDownloads(rawDownloadData);
		action.payload = downloads;
		return next(action);
	}

	// FETCH NEW DATA FOR HOURLY TRAFFIC ANALYTICS CHART
	// when the date range in redux is updated, recalc new data for the hourly chart
	if (action.type === 'analytics/getHourly') {
		let locationId = store.getState().location.selectedLocationId;
		let rawSessionData = filterSessionData(locationId);
		let hourly = aggregateHourly(rawSessionData);
		action.payload = hourly;
		return next(action);
	}

	// NEW DATE RANGE HAS BEEN SELECTED, FETCH NEW DATA FOR MOST VIEWED ANALYTICS CHART
	// when the date range in redux is updated, recalc new data for the most viewed chart
	if (action.type === 'analytics/getTopContent') {
		let { startDate, endDate } = store.getState().analytics.dateRange;
		let locationId = store.getState().location.selectedLocationId;
		// get the number of days in the date range
		let daysDiff = differenceInDays(new Date(endDate), new Date(startDate));
		// set the start date for the comparison range, the span of time prior to the current dateRange, to be the same number of days as the current dateRange
		let comparisonStart = formatISO(subDays(new Date(startDate), daysDiff));

		let rawData = store.getState().analytics.rawData;
		let rawTopContentData = rawData.filter((data) => data.type === 'top_content');
		// get top content for comparison date range
		api.getTopContent(comparisonStart, startDate, locationId).then((prevRawData) => {
			let selectedTopContent = prepareTopContent(rawTopContentData || [], locationId, store);
			let comparisonTopContent = prepareTopContent(prevRawData || [], locationId, store);
			// bundle all this together in a data structure for the chart
			let topContent = aggregateTopContent(selectedTopContent, comparisonTopContent);
			action.payload = topContent;
			return next(action);
		});
	}

	// FETCH SESSION DATA FOR USERS ANALYTICS CHART
	// when the date range in redux is updated, recalc new data for the user chart
	if (action.type === 'analytics/getSession') {
		let locationId = store.getState().location.selectedLocationId;
		let rawSessionData = filterSessionData(locationId);
		let session = aggregateSession(rawSessionData);
		action.payload = session;
		return next(action);
	}

	// FETCH VIEWSBY DATA FOR VIEWSBY ANALYTICS CHART
	// when the date range in redux is updated, recalc new data for the viewsby chart
	if (action.type === 'analytics/getViewsBy') {
		let rawData = store.getState().analytics.rawData;
		let rawTopContentData = rawData.filter((data) => data.type === 'top_content');
		let viewsBy = aggregateViewsBy(rawTopContentData);
		action.payload = viewsBy;
		return next(action);
	}

	// FETCH TRAFFIC DATA FOR TRAFFIC DAILY CHART
	// when the date range in redux is updated, recalc new data for the viewsby chart
	if (action.type === 'analytics/getTrafficDaily') {
		let locationId = store.getState().location.selectedLocationId;
		let rawSessionData = filterSessionData(locationId);
		let trafficDaily = aggregateTrafficDaily(rawSessionData);
		action.payload = trafficDaily;
		return next(action);
	}

	// WHEN SELECTING A TOPIC, ALSO SELECT ITS SECTION AND CONTENT ITEMS
	// when a page is selected in the UI, we also need to read its areaId and content array in order to set those IDs to the respective "selected" items in their slice
	if (action.type === 'topic/selectTopic') {
		next(action);
		let topicId = action.payload;
		let topic = store.getState().topic.topics[topicId];
		store.dispatch(selectSection(topic.sectionId));
		store.dispatch(selectContentIds(topic.content));
		return;
	}

	// FETCH ALL THE MODELS WHEN THIS ACTION IS INTERCEPTED
	// This action is fired from the useHydration hook. This will populate  redux with all the goodies
	if (action.type === 'location/fetchAll') {
		return api
			.getEverythingAllAtOnce()
			.then((rawData) => {
				let { org, location, areas, topics, content } = rawData;
				store.dispatch((fetchStripeInfo() as unknown) as AnyAction); // fetch our stripe customer with nested subsciption object
				store.dispatch(setIsLoading(true)); // need to immediately set the analytics loading state to true in order to prevent the UI from glitching out when the analytics are fetched later in this method

				store.dispatch(fetchOrganization(org)); // dispatch the org action to populate the org reducer
				action.payload = location;
				next(action); // dispatch the location action (this action we intercepted) to populate the location reducer
				store.dispatch(fetchSections(areas)); // dispatch the areas action to populate the areas reducer
				store.dispatch(fetchTopics(topics)); // dispatch the topics action to populate the topics reducer
				store.dispatch(fetchAllContents(content)); // dispatch the content action to populate the content reducer

				let firstSectionId = location.sectionOrder[0];
				let firstSection = areas.find((area) => area.id === firstSectionId);
				let firstTopicId = firstSection.topicOrder[0];
				store.dispatch(selectTopic(firstTopicId)); // select the first topic, this will get intercepted again and select the section and content items

				// fetch the notifications
				store.dispatch((fetchNotifications(location.id) as unknown) as AnyAction);
				// fetch the analytics, this is the first fetch when hydrating the store. Subsequent fetches are triggered by dataRange changes
				store.dispatch(getAnalyticsData());
				// fetch redirects
				store.dispatch(fetchRedirects());
				store.dispatch(isLoading(false)); //set the location slice's isLoading state to false
				return;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	//TRANSLATE THE SECTION NAMES, PAGE NAMES, AND TEXT CONTENT TO THE SPECIFIED LANGUAGES
	// this action is fired form the translate button in the editor, with specfic languages as the payload
	if (action.type === 'location/translate') {
		next(action);
		let languageCodes = action.payload.lang;
		let areaIds = action.payload.sections;
		let exhibitIds = action.payload.pages;
		return api.translate(languageCodes, areaIds, exhibitIds).then((data) => {
			let { sections, topics, content } = data;
			store.dispatch(fetchSections(sections));
			store.dispatch(fetchTopics(topics));
			store.dispatch(fetchAllContents(content));
			return;
		});
	}

	// CREATE A NEW REDIRECT
	// this action is fired when the user creates a new redirect in the editor
	if (action.type === 'redirect/createNewRedirect') {
		store.dispatch(setIsCreating(true));
		return api.createRedirect(action.payload).then((data) => {
			action.payload = data;
			return next(action);
		});
	}

	// FETCH ALL REDIRECTS
	// this action is fired during hydration
	if (action.type === 'redirect/fetchRedirects') {
		return api.getRedirects().then((data) => {
			action.payload = data;
			return next(action);
		});
	}

	// UPDATE A REDIRECT
	// this action is fired when the user updates a redirect in the editor. Currently only the name and url can be updated
	if (action.type === 'redirect/updateRedirect') {
		next(action);
		return api.updateRedirect(action.payload); // fire a network request to update the redirect, no need to await the results (thats why we call 'next' above, to let the action pass thru before the network call even takes place)
	}

	// DELETE A REDIRECT
	// this action is fired when the user deletes a redirect in the editor, interecpted here in order to fire off a network request to delete
	if (action.type === 'redirect/deleteRedirect') {
		next(action); //we can just pass the action thru without waiting for the thing we're about to do
		return api.deleteRedirect(action.payload); // fire off the network request to delete the redirect
	}

	//default case, if the action doesnt match anything above, just pass it thru
	return next(action);
};

const store = configureStore({
	reducer: rootReducer(history),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(routerMiddleware(history)).concat(loggerMiddleware).concat(liiingoMiddleware),
	devTools: true,
	// preloadedState,
	// enhancers,
});

// for accessing state w/o React Hooks
// for debugging, in console you can use window.store.dispatch({type:"editor/saveSuccessfully", payload:false}) or window.store.getState()
// window.store = store;

// hot reload reducers while developing, recommended approach
if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./rootReducer', () => {
		const newRootReducer = require('./rootReducer').default;
		store.replaceReducer(newRootReducer);
	});
}

// Infer the `AppState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
